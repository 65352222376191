@use "variables";
@use "spacing";
@use "theme";

@use "react-redux-toastr/src/styles/index";

.redux-toastr .toastr {
    $icon-width: 60px;
    $line-height: 18px;
    min-height: $line-height * 2;
    border-radius: 2px;

    .rrt-middle-container {
        margin-left: $icon-width + spacing.$spacing-base;
        .rrt-title,
        .rrt-text {
            line-height: $line-height;
        }
    }
    .rrt-left-container,
    .rrt-left-container .rrt-holder {
        width: $icon-width;
    }
    .close-toastr {
        color: white;
        font-size: variables.$font-size-base;
    }
}

.hot-toast-icon {
    &--error {
        @include theme.theme {
            --fa-primary-color: white;
            --fa-secondary-color: #{theme.get(negative)};
            --fa-secondary-opacity: 1;
        }
    }

    &--success {
        @include theme.theme {
            --fa-primary-color: white;
            --fa-secondary-color: #{theme.get(positive)};
            --fa-secondary-opacity: 1;
        }
    }

    &--info {
        @include theme.theme {
            --fa-primary-color: white;
            --fa-secondary-color: #{theme.get(primary)};
            --fa-secondary-opacity: 1;
        }
    }
}
